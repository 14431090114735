const platform = require("../harbor-platform").platform;

define(function(require, exports, module) {
  // eslint-disable-line no-undef
  const parseReference = require("core/utils/parseReference");
  const allowContribution = require("core/plugin/filters");

  const _ = require("underscore");

  function pluginContext() {
    return window.platformUI;
  }

  function contributionIndex() {
    return platform.pluginRegistry.contributionIndex.getIndex();
  }

  function generateId() {
    return (Math.random() * 100).toString();
  }

  function promiseRequire(modules, member) {
    const promFns = modules.map((mod, idx) => () =>
      new Promise(resolve =>
        window.require([mod], exportedStuff =>
          idx === modules.length - 1
            ? resolve(extractExport(exportedStuff, member))
            : resolve()
        )
      )
    );
    return sequentialPromise(promFns);
  }

  async function loadEsm(moduleDataArr, member) {
    const { get } = await import(/* webpackIgnore: true */moduleDataArr[0]);
    const initialize = await get(moduleDataArr[1]);
    return extractExport(initialize(), member);
  }

  // extract given member from object, or default attribute if es6 module
  function extractExport(module, member) {
    const finalMember = member || (module && module.__esModule ? "default" : null);
    return module && finalMember ? module[finalMember] : module;
  }

  function sequentialPromise(promiseFns) {
    return promiseFns.reduce((current, next) => current.then(next), Promise.resolve());
  }

  function injectCSS(documentOwner, href) {
    const doc = documentOwner.document;
    const link = doc.createElement("link");
    link.rel = "stylesheet";
    link.href = href;
    doc.getElementsByTagName("html")[0].appendChild(link);
  }

  function injectCSSOnce(documentOwner, href) {
    const doc = documentOwner.document;
    if (!doc.querySelectorAll(`link[href="${href}"]`).length) {
      injectCSS(documentOwner, href);
    }
  }

  function injectJS(documentOwner, file) {
    const doc = documentOwner.document;
    const script = doc.createElement("script");
    script.type = "text/javascript";
    script.async = false;
    script.src = file;
    doc.getElementsByTagName("head")[0].appendChild(script);
    return new Promise(resolve => {
      script.onload = script.onreadystatechange = function() {
        resolve();
      };
    });
  }

  function getPluginPath(pluginId) {
    const pluginPaths = window.platformUI.config.pluginPaths;
    return pluginPaths[pluginId]
  }

  function legacyPluginPath(baseUrl, path) {
    // Check if path starts with and baseUrl ends with slash to prevent double slash
    return (path[0] === "/" && baseUrl[baseUrl.length - 1] === "/" ? `${baseUrl}${path.substr(1)}` : `${baseUrl}${path}`);
  }

  function withBaseUrl(baseUrl, pluginId, path) {
    const hasScheme = path => path.indexOf("http") === 0 || path.indexOf("//") === 0;
    if (path.includes("{{PluginPath}}")) {
      return path.replace("{{PluginPath}}", getPluginPath(pluginId));
    }
    return baseUrl && !hasScheme(path) ? legacyPluginPath(baseUrl, path) : path; // Backwards compatability
  }

  function normalizeModuleDef(baseUrl, pluginId, moduleDef) {
    const isObjectForm = typeof moduleDef === "object";
    const getJsSource = (typeName) =>
      _.compact(
        _.flatten(
          isObjectForm
            ? [moduleDef[typeName]].concat(moduleDef.module) // TODO: moduleDef.module is not officially supported
            : [moduleDef]
        )
      );

    const js = getJsSource("js");
    const esm = moduleDef["esm"] ? getJsSource("esm") : null;
    const normalizeArray = val => (isObjectForm ? _.compact(_.flatten([val])) : []);
    const req = normalizeArray(moduleDef.require);
    const css = normalizeArray(moduleDef.css);
    const inject = normalizeArray(moduleDef.inject);
    const concatBase = path => withBaseUrl(baseUrl, pluginId, path);
    return {
      css: css.map(concatBase),
      js: js.map(concatBase),
      ...(esm && { esm: esm.map(concatBase) }),
      require: req,
      member: moduleDef.member,
      inject
    };
  }

  function createLoadModuleDef(contribEntry, moduleObject, options = {}) {
    const includePreload = "includePreload" in options
      ? options.includePreload
      : true;
    const pluginData = contributionIndex()[contribEntry.sourcePluginId];
    let baseUrl = (pluginData.baseUrl || "");

    const modulePreload = normalizeModuleDef(
      baseUrl,
      contribEntry.sourcePluginId,
      includePreload ? pluginData.modulePreload : {}
    );
    const moduleDef = normalizeModuleDef(
      baseUrl,
      contribEntry.sourcePluginId,
      moduleObject || contribEntry.contribution.module
    );

    return {
      css: modulePreload.css.concat(moduleDef.css),
      js: modulePreload.js.concat(moduleDef.js),
      ...(modulePreload.esm && moduleDef.esm && { esm: modulePreload.esm.concat(moduleDef.esm)}),
      require: modulePreload.require.concat(moduleDef.require),
      inject: modulePreload.inject.concat(moduleDef.inject),
      member: moduleDef.member
    };
  }

  //
  // Returns an object whose keys are the names of the module's
  // services that it wants injected, and whose values are
  // functions that return promises resolving to the respective
  // service instances.
  //
  // Given:
  //
  //```
  // module: {
  //   js: assetRef("whatever.js"),
  //   inject: {
  //     eager: true,
  //     services: [
  //       "cisco.dna.core/persistence",
  //       { service: "cisco.dna.core/telemetry", as: "telem" }
  //     ]
  //   }
  // }
  //```
  //
  // this function will return an object with keys
  //```
  // { pesistence, telem }
  //```
  //
  // Note the use of `as` to customize key names.
  //
  function injectionsForModuleDef(contribEntry, moduleDef) {
    const serviceInfo = injStr => {
      const {pluginId, name} = parseReference(injStr, contribEntry.sourcePluginId);
      return {pluginId, serviceName: name, key: name};
    };
    const injections = moduleDef.inject.map(inj =>
      _.isString(inj)
        ? serviceInfo(inj)
        : Object.assign(serviceInfo(inj.service), inj.as ? {key: inj.as} : {})
    );
    return injections.reduce(
      (injectMap, {pluginId, serviceName, key}) =>
        Object.assign(injectMap, {
          [key]: () =>
            getService(pluginId, serviceName, {pluginId: contribEntry.sourcePluginId})
        }),
      {}
    );
  }

  function injectServices(contribEntry, moduleDef, mod) {
    if (moduleDef.inject.length) {
      if (!_.isFunction(mod)) {
        // eslint-disable-next-line
        console.error( // NOSONAR
          "Module from contribEntry must export a function to receive injected services"
        );
        return mod;
      }
      return mod(injectionsForModuleDef(contribEntry, moduleDef));
    } else {
      return mod;
    }
  }

  async function loadModuleDef(contribEntry, moduleDef) {
    moduleDef.css.forEach(cssPath => injectCSSOnce(window, cssPath));
    if (moduleDef.esm) {
      const module = await loadEsm(moduleDef.esm, moduleDef.member);
      return module;
    } else {
      const allJS = moduleDef.js.concat(moduleDef.require);
      return promiseRequire(allJS, moduleDef.member).then(mod =>
        injectServices(contribEntry, moduleDef, mod)
      );
    }

  }

  function loadWorker(moduleDef) {
    const pluginPaths = window.platformUI.config.pluginPaths;
    const workerScripts = [];
    const fullModuleDef = Object.assign({pluginPaths: pluginPaths, imports: workerScripts}, moduleDef);
    const worker = new Worker(window.cisco.workerBundle);
    return new Promise(resolve => {
      const loadHandler = event => {
        if (event.data === "workerLoaded") {
          worker.removeEventListener("message", loadHandler);
          resolve(worker);
        }
      };
      worker.addEventListener("message", loadHandler);
      worker.postMessage(fullModuleDef);
    });
  }

  function createIFrame(domHolder = window.document.body, settings = {}) {
    const id = generateId();
    const iframe = document.createElement("iframe");
    iframe.id = id;
    iframe.frameBorder = 0;
    iframe.scrolling = "no";
    iframe.name = id;

    if (settings.maxHeight) {
      iframe.height(settings.maxHeight);
    }
    if (settings.maxWidth) {
      iframe.width(settings.maxWidth);
    }
    // If no dom holder is supplied, we presume this isn't an interactive on page widget
    if (!domHolder) {
      iframe.css({display: "none"});
    }
    return [
      id,
      new Promise(resolve => {
        // firefox gets wonky unless we wait for onload
        iframe.onload = () => resolve(window.frames[id]);
        iframe.appendTo(domHolder);
      })
    ];
  }

  function addMessageListener(iframeName, handler) {
    const listener = event => {
      if (!window.frames[iframeName]) {
        // remove listeners that belong to sandboxes
        // whose iframes are no longer in the DOM
        remove();
      } else if (event.source && event.source.name === iframeName) {
        const dataKey = event.message ? "message" : "data";
        handler(event[dataKey]);
      }
    };
    window.addEventListener("message", listener, false);
    function remove() {
      window.removeEventListener("message", listener, false);
    }
    return remove;
  }

  function sandboxExportProxy(iframe, keys) {
    const proxy = {};
    keys.forEach(key => {
      proxy[key] = function() {
        const messageID = generateId();
        const result = new Promise(resolve => {
          const remove = addMessageListener(iframe.name, data => {
            if (data.messageID === messageID) {
              remove();
              resolve(data.response);
            }
          });
        });
        iframe.postMessage(
          {key, messageID, args: Array.prototype.slice.call(arguments)},
          window.location.href
        );
        return result;
      };
    });
    return proxy;
  }

  function waitForExport(iframe) {
    return new Promise(resolve => {
      const remove = addMessageListener(iframe.name, data => {
        if (data.message && data.message === "export") {
          remove();
          resolve(data.keys ? sandboxExportProxy(iframe, data.keys) : data.value);
        }
      });
    });
  }

  function createMessageHandler(iframeName) {
    const handlers = {};
    const dispose = addMessageListener(iframeName, data => {
      if (data.trigger) {
        (handlers[data.trigger] || []).forEach(listener => listener(data.payload));
      }
    });
    return {
      on(trigger, handler) {
        if (!handlers[trigger]) {
          handlers[trigger] = [];
        }
        handlers[trigger].push(handler);
        return function remove() {
          handlers[trigger].splice(handlers[trigger].indexOf(handler), 1);
        };
      },
      dispose
    };
  }

  function prependSandboxDeps(normModuleDef) {
    // prepend core dependencies that sandboxed code depends on
    const sandboxScripts = [
      "/core/requirejsAdmin.js",
      "/core/utils/pluginTriggers.js",
      ...window.cisco.coreBundles
    ];
    return {
      css: ["/core/css/common.css"].concat(normModuleDef.css),
      js: sandboxScripts.concat(normModuleDef.js),
      require: normModuleDef.require
    };
  }

  //
  // Inject scripts referenced in normModuleDef into the given window;
  // Used for injecting scripts into iframes.
  //
  function injectModuleDef(documentOwner, normModuleDef) {
    normModuleDef.css.forEach(cssFile => injectCSS(documentOwner, cssFile));
    const scriptsLoaded = Promise.all(
      normModuleDef.js.map(jsFile => injectJS(documentOwner, jsFile))
    );
    if (normModuleDef.require.length) {
      const requireArrayStr = normModuleDef.require.map(r => `"${r}"`).join(", ");
      scriptsLoaded.then(() => {
        const doc = documentOwner.document;
        const script = doc.createElement("script");
        script.type = "text/javascript";
        script.textContent = `requirejs([${requireArrayStr}], function(mod) { if (mod !== undefined) window.__pluginSandboxContext.__export(mod.__esModule ? mod.default : mod) })`;
        script.setAttribute("nonce", pluginContext().nonce);
        doc.getElementsByTagName("html")[0].appendChild(script);
      });
    }
  }

  function renderSandbox(normModuleDef, domHolder, settings) {
    const [id, iframePromise] = createIFrame(domHolder, settings);
    const exportPromise = iframePromise.then(iframe => waitForExport(iframe));
    const messageHandler = createMessageHandler(id);
    iframePromise.then(iframe =>
      injectModuleDef(iframe, prependSandboxDeps(normModuleDef))
    );
    return {
      /**
       * @return {Promise} Resolves to whatever the sandboxed module exports via its __pluginSandboxContext.export()
       */
      getExport() {
        return exportPromise;
      },

      /**
       * Add handler for a message sent from the sandbox, via its __pluginSandboxContext.sendMessage(trigger, payload)
       * @param  {string}   trigger The trigger (event) to listen for
       * @param  {Function} handler The handler to be invoked with the sent message's payload
       * @return {Function}         A function that can be invoked to remove the added handler at some later time
       */
      on(trigger, handler) {
        return messageHandler.on(trigger, handler);
      },

      dispose() {
        messageHandler.dispose();
        iframePromise.then(iframe => {
          if (iframe.parentNode) iframe.parentNode.removeChild(iframe);
        });
      }
    };
  }

  /**
   * Return an instance of the requested service.
   * @param  {string} pluginId     id of the plugin that provides the service
   * @param  {string} serviceName  the name of the service
   * @return {Promise}             a Promise that resolves to the service, or null if not found
   */
  function getService(pluginId, serviceName, context) {
    const matches = contributions("cisco.dna.core")
      .forExtensionPoint("service")
      .filter(
        entry => entry.sourcePluginId === pluginId && entry.data.name === serviceName
      );
    if (matches.length > 1) {
      // eslint-disable-next-line
      console.warn(`more that one service found matching ${serviceName}`); // NOSONAR
    }
    const initServiceWithContext = service => {
      if (matches[0].data.requiresContext) {
        if (context) {
          return service(context);
        } else {
          throw new Error(
            `Service ${pluginId}/${serviceName} can only be used via injection`
          );
        }
      } else {
        return service;
      }
    };
    return matches.length
      ? matches[0].loadModule().then(initServiceWithContext)
      : Promise.resolve(null);
  }

  function forQualifiedExtensionPoint(qualifiedExtensionPoint, defaultNamespace) {
    const {pluginId, name} = parseReference(qualifiedExtensionPoint, defaultNamespace);
    return contributions(pluginId).forExtensionPoint(name);
  }

  function contributions(pluginId) {
    function pluginContributions() {
      return (
        (contributionIndex()[pluginId || pluginContext().pluginId] || {}).contributions ||
        []
      );
    }

    /**
     * Returns the contribution entries for the given extension point.
     * @param  {string} extensionPoint
     * @return {array{loadModule, data, sourcePluginId}} contribution entries for the given extension point.
     */
    function forExtensionPoint(extensionPoint) {
      return pluginContributions()
        .filter(
          contribEntry =>
            contribEntry.contribution.extensionPoint === extensionPoint &&
            allowContribution(
              contribEntry.contribution,
              contribEntry.sourcePluginId,
              `${pluginId}/${extensionPoint}`
            )
        )
        .map(contribEntry => {
          return {
            /**
             * Returns a resolved path to the given asset, based on the
             * given plugin's base url.
             */
            resolveAsset: assetPath => {
              const pluginData = contributionIndex()[contribEntry.sourcePluginId];
              const baseUrl = pluginData.baseUrl;
              return withBaseUrl(baseUrl, contribEntry.sourcePluginId, assetPath);
            },

            /**
             * Load a module from this contribution.  This ensures that
             * any of the contributing plugin's modulePreload files are loaded
             * before loading the contribution's module.
             *
             * Returns the returning the final export.
             * @param  {string} moduleObject An object of json schema type
             *                               "dna/ui/plugin#/module".  If this parameter
             *                               is not specified, the module object
             *                               is obtained from the "module"
             *                               attribute of the contribution.
             * @return {Promise} A promise that resolves to whatever the module exports.
             */
            loadModule: moduleObject => {
              return loadModuleDef(
                contribEntry,
                createLoadModuleDef(contribEntry, moduleObject)
              );
            },

            /**
             *
             */
            loadWorker: moduleObject => {
              return loadWorker(
                createLoadModuleDef(contribEntry, moduleObject, {includePreload: false})
              );
            },

            /**
             * Load the given module in an IFramed sandbox.
             * @param  {string} domHolder A DOM element or selector to render the IFrame within.
             *                            If not specified, render within document.body.
             * @param  {HtmlElement|string} domHolder The selector or DOM element to render the IFrame into.
             *                                        Defaults to document.body.
             * @param  {string} moduleProperty Optional name of config attribute
             *                                 referring to the module. Defaults
             *                                 to "module"
             * @param  {Object} settings  IFrame options {maxWidth, maxHeight}
             * @return {Sandbox} Sandbox object
             */
            renderSandbox: (domHolder, settings = {}, moduleObject) => {
              return renderSandbox(
                createLoadModuleDef(contribEntry, moduleObject),
                domHolder,
                settings
              );
            },

            /** @type {Object} The data provided */
            data: contribEntry.contribution,

            /** @type {string} id of contributing plugin. */
            sourcePluginId: contribEntry.sourcePluginId
          };
        });
    }

    return {
      forExtensionPoint
    };
  }

  contributions.getService = getService;
  contributions.forQualifiedExtensionPoint = forQualifiedExtensionPoint;

  module.exports = contributions;
});