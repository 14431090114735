define(function(require) {
  const PluginContributions = require("core/utils/PluginContributions");

  return {
    /**
     * Return an instance of the requested service.
     * @param  {string} pluginId     id of the plugin that provides the service
     * @param  {string} serviceName  the name of the service
     * @return {Promise}             a Promise that resolves to the service, or null if not found
     */
    getService(pluginId, serviceName) {
      return PluginContributions.getService(pluginId, serviceName);
    }
  };
});
