/**
 * Prevents polyfill for certain functions, in order to prevent an instance 
 * where one app overrides the functionality of a function.
 * 
 * These types of bugs are typically hidden and difficult to trace,
 * so to enforce the rule we'll completely prevent overriding js functions
 */
export function polykill(blockList: [unknown, string][]) : void {
  const blockMap = new Map<unknown, Set<string>>();

  for (const [rootObj, prop] of blockList) {
    const blockedProps = blockMap.get(rootObj) || new Set();
    blockedProps.add(prop);
    blockMap.set(rootObj, blockedProps);

    const orig = Object.getOwnPropertyDescriptor(rootObj, prop);

    if (!orig)
      continue;

    Object.defineProperty(rootObj, prop, {
      configurable: false, // prevent polyfilling by invocation of defineProperty
      get() {
        return orig.value;
      },
      set() {/** Ignore */}
    });
  }

  // If code attempts to re-define a property that
  // we've blocked, defineProperty will normally
  // throw an exception.   To prevent catastrophic
  // failure, we override defineProperty to catch
  // this exception and print a warning to the
  // console instead.
  const origDP = Object.defineProperty.bind(Object);
  Object.defineProperty = function(obj, prop, ...args) {
    try {
      return origDP(obj, prop, ...args);
    } catch (err) {
      const keys = blockMap.get(obj) || new Set();
      if (keys.has(prop as string)) {
        // console.warn(`Polyfill of ${obj}.${prop} is not permitted\n`, err);
        return obj;
      } else {
        throw err;
      }
    }
  };
}