import "dnx-web-components/dist/commons.min";
import "./amcharts";
import * as Utils from "dnx-web-components/dist/index-with-deps.min";
import * as ReactComponents from "dnx-web-components/dist/index-with-deps-react.min";
import * as Icons from "@cisco-dna/icons";
import * as DnxTable from "dnx-web-components/dist/table.min";
import * as Chunks from "dnx-web-components/dist/index-chunks.min";
import * as ReactChunks from "dnx-web-components/dist/index-chunks-react.min";

export const dnaIcons = (): Promise<unknown> => Promise.resolve(Icons);

export const amCharts = (): Promise<unknown> =>
   import(/* webpackChunkName: "amcharts" */ "./amcharts");

export const dnxWebComponents = async (): Promise<unknown> =>
   Promise.resolve(Utils);
//    await import(
//       /* webpackChunkName: "dnx-commons.min" */ "dnx-web-components/dist/commons.min.js"
//    );

//    const [index] = await Promise.all([
//       import(
//          /* webpackChunkName: "dnx-index-with-deps.min" */ "dnx-web-components/dist/index-with-deps.min.js"
//       ),
//       dnaIcons(),
//       amCharts(),
//    ]);

//    return index;
// };

export const dnxReactComponents = async (): Promise<unknown> =>
   Promise.resolve({ default: ReactComponents });

export const dnxTable = (): Promise<unknown> => Promise.resolve(DnxTable);
// import(
//    /* webpackChunkName: "dnx-table.min" */ "dnx-web-components/dist/table.min"
// );

export const dnxTableReact = (): Promise<unknown> =>
   Promise.resolve(ReactComponents.DtableWrapper(DnxTable.default));
// Promise.all([dnxReactComponents(), dnxTable()]).then(([rc, table]) =>
//    // eslint-disable-next-line @typescript-eslint/no-explicit-any
//    (rc as any).DtableWrapper((table as any).default)
// );

export const dnxChunks = (): Promise<unknown> => Promise.resolve(Chunks);
// import(
//    /* webpackChunkName: "dnx-index-chunks.min" */ "dnx-web-components/dist/index-chunks.min"
// );

export const dnxChunksReact = (): Promise<unknown> =>
   Promise.resolve({ default: ReactChunks });
// import(
//    /* webpackChunkName: "dnx-index-chunks-react.min" */ "dnx-web-components/dist/index-chunks-react.min"
// );
