import { injectScript, injectStylesheet } from "../Inject";

// Harbor Elements just needs an initial module loaded to the DOM
export function initHarborElements(): void {
   // breaks cache whenever elements gets upticked
   const hash =
      "?" + (process.env.HARBOR_ELEMENTS_VERSION ?? "0").replace(/\./g, "");

   injectScript("/harbor-elements/harbor-elements.esm.js" + hash, "module");

   [
      "/harbor-elements/harbor-elements.css",
      "/harbor-elements/styles/themes/dark.css",
      "/harbor-elements/styles/tokens/base-font-10/magnetic.css",
      "/harbor-elements/styles/themes/magnetic.css",
      "/harbor-elements/styles/tokens/base-font-10/magnetic.dark.css",
      "/harbor-elements/styles/themes/magnetic.dark.css",
      "/harbor-elements/styles/themes/magnetic-deprecated.css",
      "/harbor-elements/styles/themes/magnetic-classic.css",
   ]
      .map((x) => x + hash)
      .forEach(injectStylesheet);
}
