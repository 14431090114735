/* eslint-disable @typescript-eslint/no-explicit-any */


/**
 * If a module is defined as a promise, this AMD loader will
 * wait for the promise to resolve before resolving itself.
 * 
 * This is most useful when defining a library that shouldn't be
 * imported/loaded until it is needed, to save network times
 * 
 * ```js
 * require(['promise-loader!module-name'], mod => /** mod *\/);
 * ```
 */
export const promiseLoader: RequirePlugin = {
  load: function(name, req, onload) {
    // req has the same API as require().
    req([name], function(value: any) {
      if (value && typeof value.then === "function") {
        value.then(onload);
      } else {
        onload(value);
      }
    });
  }
};

/**
 * 
 * @param name 
 * @param importFn 
 */
export function defineLazy(name: string, importFn: () => Promise<unknown>) : void {
  window.define(`__lazy__${name}`, importFn);
  window.define(name, [`promise-loader!__lazy__${name}`], (m: any) => m.default);
}

/**
 * Loads ui-plugin services via an AMD shim
 * 
 * ```js
 * import Router from "dna-service!cisco.dna.core.shell/router";
 * ```
 * 
 * @deprecated
 */
export const serviceLoader: RequirePlugin = {
  load: function(name, req, onload) {
    req(["core/utils/serviceRegistry"], function(registry: any) {
      const [pluginId, serviceName] = name.split("/")
      const service = registry.getService(pluginId, serviceName)
      service.then(onload)
    })
  }
}