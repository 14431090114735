const deprecated = (): never => {
   throw new Error("core/plugin/featureFlags is deprecated");
};

// Note: this module is deprecated!
// For backwards support, we can have a ui-plugin monkeypatch the proper methods in.
// Check the following plugins for actual implementation:
//  * cisco.dna.core.featureFlags (cloud)
//  * cisco.harbor.platform (vm)
export default {
   isEnabled: deprecated,
   enable: deprecated,
   disable: deprecated,
};
