// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__Option from "@rescript/core/src/Core__Option.res.js";

var dnvWebComponents = ((document.createElement("dnx-context")).version);

function packages() {
  return {
          harbor: Core__Option.getOr(process["env.HARBOR_PACKAGE_VERSION"], ""),
          "dnv-web-components": dnvWebComponents,
          react: Core__Option.getOr(process["env.REACT_VERSION"], "")
        };
}

export {
  dnvWebComponents ,
  packages ,
}
/* dnvWebComponents Not a pure module */
