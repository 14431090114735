define(function() {
  /**
   * Returns the constituent parts of a reference.
   * For example, a referenceStr of "cisco.dna.foo/bar" yields
   *    {pluginId: "cisco.dna.foo", name: "bar"}
   * If referenceStr contains no forward slash, it is interpreted as a reference
   * relative to referencerPluginId.  For example parseReference("baz", "cisco.dna.bar")
   * yields {pluginId: "cisco.dna.bar", name: "baz"}
   */
  return function parseReference(referenceStr, referencerPluginId) {
    const [pluginId, name] = referenceStr.split("/");
    return name
      ? {pluginId, name}
      : referencerPluginId
        ? {pluginId: referencerPluginId, name: referenceStr}
        : (() => {
          throw new Error(`Cannot parse unqualified reference "${referenceStr}"`);
        })();
  };
});
