define([], function() {
  return {
    /**
     * Returns the teplate string with placeholders replaced with values
     * from the given data object.
     *
     * @param template represents the string with handlebars style placeholders
     * @param data object with named values to be substituted in the template
     */
    getFilledTextForHBS: function(template, data) {
      let parsedTemplated = template;
      for (const [key, value] of Object.entries(data)) {
        parsedTemplated = parsedTemplated.replace(new RegExp(`{{ *?${key} *?}}`, "gi"), value);
      }
      return parsedTemplated;
    }
  };
});
