import axios, {
   type AxiosInstance,
   type InternalAxiosRequestConfig,
} from "axios-1.x";
import { secondsToMilliseconds } from "date-fns";

// How long should a request hang before being deemed unresponsive
const REQUEST_TIMEOUT = secondsToMilliseconds(60);

type PreventCache = {
   preventCache?: boolean;
};

//
// Handle an extra
//
const preventCache = (config: InternalAxiosRequestConfig & PreventCache) => {
   if (config.preventCache) {
      config.params = Object.assign({}, config.params, {
         __preventCache: new Date().getTime(),
      });
   }

   return config;
};

//
// Set a timeout for requests,
// ignoring non rest API requests to prevent timing out on long last file uploads
//
const setTimeout = (config: InternalAxiosRequestConfig) => {
   const contentType = config.headers["Content-Type"] ?? "";

   if (
      typeof contentType === "string" &&
      contentType.includes("application/json")
   ) {
      config.timeout = REQUEST_TIMEOUT;
   }

   return config;
};

export function createSharedInstance(): AxiosInstance {
   const instance = axios.create();

   // append all the default helpers to this axios instance
   // unsure which are being used so just copied over every property
   Object.assign(instance, {
      Axios: axios,
      default: axios,
      all: axios.all,
      create: axios.create,
      spread: axios.spread,
      Cancel: axios.Cancel,
      CancelToken: axios.CancelToken,
      isCancel: axios.isCancel,
   });

   instance.interceptors.request.use(preventCache);
   instance.interceptors.request.use(setTimeout);

   return instance;
}
