const publicPath = window.platformUI.config.basePath + "dist";

export const injectScript = (
   src: string,
   type = "text/javascript"
): Promise<HTMLScriptElement> =>
   new Promise((resolve) => {
      src = publicPath + src;
      const existing = document.querySelector("script[src='" + src + "']");
      if (existing) return resolve(existing as HTMLScriptElement);

      const script = document.createElement("script");
      script.src = src;
      script.type = type;
      script.onload = () => resolve(script);
      document.head.append(script);
   });

export const loadUmdScript = (src: string): Promise<unknown> =>
   new Promise((resolve) => window.require([publicPath + src], resolve));

export const injectStylesheet = (href: string): Promise<HTMLLinkElement> =>
   new Promise((resolve) => {
      href = publicPath + href;
      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.href = href;
      document.head.append(link);
      resolve(link);
   });
