import { platform } from "./core/harbor-platform";

const ignore = () => {
   /** noop */
};

/**
 * Expose an API for the developer plugin to interact with the platform store.
 * @internal Will be removed without warning, do not use!
 */
export function createInternalPluginAPI(): void {
   const event = new EventTarget();

   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   (<any>window).__harbor_internal_devplugins__ = {
      addEventListener: event.addEventListener.bind(event),
      removeEventListener: event.removeEventListener.bind(event),

      get devUrls(): string[] {
         return platform.pluginRegistry.getDevPluginUrls();
      },

      get pluginConfigs(): ReturnType<
         typeof platform.pluginRegistry.getPluginConfigs
      > {
         return platform.pluginRegistry.getPluginConfigs();
      },

      async addUrl(url: string) {
         await platform.pluginRegistry.addDevPlugin(url).catch(ignore);
         event.dispatchEvent(new Event("change"));
      },

      async clear() {
         await platform.pluginRegistry.clearDevPlugins().catch(ignore);
         event.dispatchEvent(new Event("change"));
      },

      async removeUrl(url: string) {
         await platform.pluginRegistry.removeDevPlugin(url).catch(ignore);
         event.dispatchEvent(new Event("change"));
      },
   };
}
